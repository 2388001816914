/** @jsx jsx */
import { jsx } from "theme-ui"

const Button = props => (
  <button
    {...props}
    sx={{
      variant: "button.default",
    }}
  />
)

export default Button
