// import "babel-polyfill"
import appClient from "src/app-client.js"
export const wrapPageElement = appClient

export const shouldUpdateScroll = ({
  routerProps: { location },
  prevRouterProps,
  getSavedScrollPosition,
}) => {
  if (prevRouterProps) {
    const { location: prevLocation } = prevRouterProps
    const currentPosition = getSavedScrollPosition(location)

    if (
      location.pathname.match(/\/search\/?/) &&
      prevLocation.pathname.match(/\/search\/?/)
    ) {
      return false
    }

    if (
      location.pathname.match(/\/search\/?/) &&
      !prevLocation.pathname.match(/\/search\/?/)
    ) {
      setTimeout(() => {
        window.scrollTo(...currentPosition)
      }, 1) // Code smell bc Algolia sucks
      // A less angry way of putting it:
      // Algolia seems to be doing some sort of scroll position setting which is messing things up.
      // The timeout of 1ms fixes things but it not a nice way of doing it.
      return false
    }
  }

  return true
}

export const onClientEntry = () => {
  (function (f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

  fbq('init', process.env.GATSBY_META_PIXEL);
  fbq('track', 'PageView');
};

export const onRouteUpdate = ({ location }) => {
  if (typeof window !== 'undefined') {
    fbq('track', 'PageView', { path: location.pathname });
  }
};
