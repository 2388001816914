import React from "react"
import { ThemeProvider, Themed } from "theme-ui"
import theme from "src/theme"

import "minireset.css/minireset.css"
import "src/assets/fonts/index.css"
import "src/assets/css/styles.scss"

import ClientOuter from "./components/ClientOuter"
import Layout from "src/components/Layout"
import PerVisitorSiteWideAnnouncement from "src/components/PerVisitorSiteWideAnnouncement"

const AppClient = ({ element, props }) => {
  return (
    <ThemeProvider theme={theme}>
      <ClientOuter location={props.location} />
      <Themed.root>
        <Layout>{element}</Layout>
        <PerVisitorSiteWideAnnouncement />
      </Themed.root>
    </ThemeProvider>
  )
}

export default AppClient
