import create from "zustand"

const useStore = create(set => ({
  nav: null,
  mobileNav: false,

  setNav: id => set({ nav: id }),
  setMobileNav: state =>
    set({ mobileNav: state}),
  toggleNav: id => set(state => ({ nav: id === state.nav ? null : id })),
  toggleMobileNav: id =>
    set(state => ({ mobileNav: state.mobileNav ? false : true })),
  closeMobileNav: () => set(state => ({ mobileNav: false })),
  closeNav: () => set(state => ({ nav: null })),
}))

export default useStore
