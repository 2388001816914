/** @jsx jsx */
import { jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import useLocalStorageState from "src/hooks/useLocalStorageState"
import Dialog from "src/components/Dialog"

const PerVisitorSiteWideAnnouncement = () => {
  const {
    wpgraphql: {
      announcements: { nodes },
    },
  } = useStaticQuery(graphql`
    {
      wpgraphql {
        announcements(first: 1) {
          nodes {
            id
            acfAnnouncement {
              bodyContent {
                ... on WPGraphQL_Announcement_Acfannouncement_BodyContent_Wysiwyg {
                  title
                  content
                  buttonText
                }
              }
            }
          }
        }
      }
    }
  `)

  // If there is no announcement in WordPress don't render an announcement
  if (nodes.length < 1) return null
  // If there is no content in the announcement don't render an announcement
  if (nodes[0].acfAnnouncement.bodyContent.length < 1) return null

  const {
    id,
    acfAnnouncement: {
      bodyContent: [{ title, content, buttonText }],
    },
  } = nodes[0]

  const [
    hasSeenPerVisitorSiteWideAnnouncement,
    setHasSeenPerVisitorSiteWideAnnouncement,
  ] = useLocalStorageState(`seen-site-wide-announcement:${id}`, false)

  if (hasSeenPerVisitorSiteWideAnnouncement) {
    return null
  }

  return (
    <Dialog
      id={id}
      title={title}
      content={content}
      buttonText={buttonText}
      setHasSeenPerVisitorSiteWideAnnouncement={
        setHasSeenPerVisitorSiteWideAnnouncement
      }
    />
  )
}

export default PerVisitorSiteWideAnnouncement
