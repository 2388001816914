// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-type-articles-js": () => import("./../../../src/templates/postType-articles.js" /* webpackChunkName: "component---src-templates-post-type-articles-js" */),
  "component---src-templates-post-type-legal-interventions-js": () => import("./../../../src/templates/postType-legalInterventions.js" /* webpackChunkName: "component---src-templates-post-type-legal-interventions-js" */),
  "component---src-templates-post-type-news-and-events-js": () => import("./../../../src/templates/postType-newsAndEvents.js" /* webpackChunkName: "component---src-templates-post-type-news-and-events-js" */),
  "component---src-templates-post-type-policy-submissions-js": () => import("./../../../src/templates/postType-policySubmissions.js" /* webpackChunkName: "component---src-templates-post-type-policy-submissions-js" */),
  "component---src-templates-post-type-resources-js": () => import("./../../../src/templates/postType-resources.js" /* webpackChunkName: "component---src-templates-post-type-resources-js" */),
  "component---src-templates-taxonomy-hubs-js": () => import("./../../../src/templates/taxonomyHubs.js" /* webpackChunkName: "component---src-templates-taxonomy-hubs-js" */)
}

