import { pxToRem } from "src/helpers"

const heading = {
  fontFamily: "heading",
  lineHeight: "heading",
  fontWeight: "heading",
}

const body = {
  fontFamily: "body",
  lineHeight: "body",
  fontWeight: "body",
}

const colors = {
  text: "#000",
  background: "#fff",
  primary: "#33e",
  white: "#fff",
  yellow: "#EDB936",
  yellows: ["#EDB936", "#E6A80D"],
  blue: "#071C53",
  blues: ["#36acfb", "#071C53"],
  green: "#62DEBD",
  red: "#B22304",
  reds: ["#B22304", "#911d04"],
  black: "#000000",
  grey: "#585858",
  greys: ["#585858"],
  orange: "#F96204",
  navBgOne: "#071C53",
  navBgTwo: "#203364",
  navBgThree: "#394975",
}

export const bgThemes = {
  white: {}, // This had been commented out by prev developer. made a whiteAlt color for hero button
  whiteAlt: {
    bg: "white",
    color: "black",
    a: {
      color: "black",
    },
  },
  red: {
    bg: "red",
    color: "white",
    a: {
      color: "white",
    },
  },
  yellow: {
    bg: "yellow",
    color: "black",
    a: {
      color: "black",
    },
  },
  blue: {
    bg: "blue",
    color: "white",
    a: {
      color: "white",
    },
  },
  green: {
    bg: "green",
    color: "black",
    a: {
      color: "black",
    },
  },
}

export const headerThemes = {
  default: {
    color: "black",
    bg: "transparent",
  },
  light: {
    color: "white",
    bg: "transparent",
  },
  dark: {
    color: "black",
    bg: "transparent",
  },
  active: {
    color: "black",
    bg: ["transparent", null, null, null, "white"],
  },
}

export const headerButtonThemes = {
  default: {
    color: "black",
    bg: "green",
  },
  light: {
    color: "black",
    bg: "green",
  },
  dark: {
    color: "white",
    bg: "blue",
  },
  active: {
    color: "black",
    bg: "green",
  },
}

const boxShadows = {
  small: {
    boxShadow: `0px 4px 16px rgba(76, 48, 16, 0.16)`,
  },
  large: {
    boxShadow: `0px 12px 40px rgba(39, 23, 17, 0.16)`,
  },
}

const sizes = {
  container: pxToRem([null, 400, 600, 760, 960, 1200]),
  containerHero: pxToRem([1200]),
  containerWide: pxToRem(1400),
  containerMax: pxToRem(1440),
}

const buttonReset = {
  padding: 0,
  border: "none",
  font: "inherit",
  color: "inherit",
  backgroundColor: "transparent",
  appearance: "none",
  cursor: "pointer",
  "::-moz-focus-inner": {
    border: 0,
  },
}

export const focusOutline = {
  "&:focus-visible": {
    outline: "2px solid",
    outlineOffset: "2px",
    outlineColor: colors.yellows[1],
  },
}

export const focusOutlineTight = {
  "&:focus-visible": {
    outline: "2px solid",
    outlineColor: colors.yellows[1],
  },
}

const styles = {
  root: {
    ...body,
  },
  h1: {
    ...heading,
    fontSize: pxToRem([30, null, 38, 44, 50]),
    lineHeight: 1,
    mb: "0.8em",
  },
  h2: {
    ...heading,
    fontSize: pxToRem([24, null, null, 30, 34]),
    mb: "1.25em",
  },
  h3: {
    ...heading,
    fontSize: pxToRem([20, null, null, null, 24]),
    mb: "0.65em",
  },
  h4: {
    ...heading,
    fontSize: pxToRem([16, null, null, null, 18]),
    mb: "1em",
  },
  p: {
    ...body,
    fontSize: 2,
    mb: 5,
  },
  a: {
    fontWeight: "bold",
    color: "black",
    ...focusOutline,
    "&:hover": {
      opacity: 0.8,
    },
  },
  ol: {
    paddingLeft: "1.2em",
    mb: 5,
  },
  ul: {
    paddingLeft: "1em",
    "[dir=rtl] &": {
      paddingRight: "1em",
      paddingLeft: 0,
    },
    mb: 5,
    li: {
      listStyleType: "disc",
    },
  },
  small: {
    ...body,
    fontSize: 1,
  },
  summary: {
    ...focusOutline,
  },
}

export const focusBackground = {
  outline: 0,
  "&:focus": {
    backgroundColor: colors.yellows[1],
  },
}

export const layoutWidths = {
  colsFour: ["50%", null, "50%", null, "25%"],
}

export const subHearderHeight = pxToRem([96, null, null, 143])

const breakpoints = pxToRem([350, 650, 800, 1000, 1280, 1440])

const alertBase = {
  fontWeight: "body",
  borderRadius: 0,
  p: {
    ...styles.p,
    mb: 0,
  },
}

export default {
  //      0  1  2  3   4   5   6   7   8  <== number guide
  space: [0, 4, 8, 16, 20, 24, 32, 64, 76],
  //                  0   1   2   3   4   5   6   7   8   9  <== number guide
  fontSizes: pxToRem([12, 13, 16, 18, 20, 24, 30, 34, 48, 64]),
  breakpoints,
  fonts: {
    body: "Graphik, sans-serif",
    heading: "Graphik, sans-serif",
  },
  fontWeights: {
    body: 400,
    heading: 700,
  },
  lineHeights: {
    body: 1.625,
    heading: 1.125,
    headingLarge: 1,
  },
  colors,
  altColors: {
    red: {
      color: "white",
    },
    green: {
      color: "white",
    },
    blue: {
      color: "white",
    },
    yellow: {
      color: "black",
    },
  },
  styles,
  text: {
    sectionHeading: {
      ...styles.h2,
      mb: "1em",
    },
    heroTitle: {
      ...styles.h1,
      mb: "0.65em",
    },
    intro: {
      fontSize: pxToRem([20, 20, 20, 24]),
      fontWeight: 500,
      lineHeight: "1.333333",
      letterSpacing: "-0.03em",
    },
    smallText: {
      opacity: 0.6,
      // color: "grey",
      fontSize: 1,
      lineHeight: 1.4,
    },
    mobNavLink1: {
      color: "#fff",
      fontWeight: "bold",
      fontSize: pxToRem(28),
      lineHeight: 1.2,
      ...focusOutline,
    },
    mobNavLink2: {
      color: "#fff",
      fontWeight: "bold",
      fontSize: 5,
      lineHeight: 1.2,
      ...focusOutline,
      textShadow: "0px 4px 4px rgba(0,0,0,0.25)",
    },
    mobNavLink3: {
      color: "#fff",
      fontWeight: "bold",
      fontSize: 2,
      ...focusOutline,
      textShadow: "0px 4px 4px rgba(0,0,0,0.25)",
    },
    mobNavLink3large: {
      color: "#fff",
      fontWeight: "bold",
      fontSize: 3,
      ...focusOutline,
      textShadow: "0px 4px 4px rgba(0,0,0,0.25)",
    },
  },
  spacing: {
    heroImageRight: {
      mb: [5, 5, 6, 7],
    },
    heroStandard: {
      mb: [5, 5, 6, 7],
    },
    heroHome: {
      mb: [5, 5, 6, 7],
    },
    small: {
      py: [3, 3, 4, 5],
    },
    medium: {
      py: [5, 5, 6, 7],
    },
  },
  links: {
    h3: {
      ...styles.h3,
      ...focusOutline,
      textDecoration: "underline",
    },
    h4: {
      ...styles.h4,
      ...focusOutline,
      textDecoration: "underline",
    },
    footerNav: {
      ...styles.h4,
      ...focusOutline,
    },
  },
  layout: {
    container: {
      maxWidth: sizes.container,
      mx: "auto",
    },
    containerHero: {
      maxWidth: sizes.containerHero,
      mx: "auto",
    },
    containerWide: {
      maxWidth: sizes.containerWide,
      mx: "auto",
    },
    containerMax: {
      maxWidth: sizes.containerMax,
      mx: "auto",
    },
  },
  section: {
    narrowSides: {
      px: 2,
    },
    small: {
      py: [3, 3, 4, 5],
    },
    medium: {
      py: [4, 4, 5, 6],
    },
    mediumHeadline: {
      pt: [5, 5, 6, 7],
      pb: [3, 3, 4, 5],
    },
    large: {
      py: [5, 5, 6, 7],
    },
    xlarge: {
      py: [5, 5, 6, 7, 8],
    },
    largeColored: {
      py: [pxToRem(50), null, null, pxToRem(80), pxToRem(100)],
      my: [pxToRem(30), null, null, null, pxToRem(40)],
      "+ .section-bg": {
        mt: [pxToRem(-30), null, null, null, pxToRem(-40)],
      },
    },
    smallColored: {
      py: [pxToRem(25), null, null, pxToRem(40), pxToRem(50)],
      my: [pxToRem(15), null, null, null, pxToRem(20)],
      "+ .section-bg": {
        mt: [pxToRem(-15), null, null, null, pxToRem(-20)],
      },
    },
  },
  gradient: {
    default: {
      background: `linear-gradient(90deg, ${colors.red} 0% 36%, ${colors.yellow} 36% 57%, ${colors.blue} 57% 70%, ${colors.green} 70% 100%)`,
    },
  },
  link: {
    text: {
      ...focusOutline,
      color: "black",
      "&:hover": {
        opacity: 0.8,
      },
    },
    textSmall: {
      ...focusOutline,
      fontSize: pxToRem(13),
      color: "grey",
      "&:hover": {
        opacity: 0.8,
      },
    },
    skipNav: {
      left: "-999px",
      position: "absolute",
      top: "auto",
      width: "1px",
      height: "1px",
      overflow: "hidden",
      zIndex: "-999",
      "&:focus,&:active": {
        color: "black",
        bg: "white",
        left: 2,
        top: 2,
        width: "auto",
        height: "auto",
        overflow: "auto",
        padding: "5px",
        zIndex: "999",
        outline: "2px solid",
        outlineColor: colors.yellows[1],
      },
    },
  },
  button: {
    void: {
      ...buttonReset,
      ...focusOutlineTight,
    },
    reset: {
      ...buttonReset,
      ...focusOutlineTight,
    },
    default: {
      ...buttonReset,
      ...focusOutlineTight,
      fontSize: pxToRem(18),
      fontWeight: "bold",
      borderRadius: "4px",
      px: 4,
      py: 2,
    },
    whiteShadowed: {
      ...buttonReset,
      ...focusOutlineTight,
      ...boxShadows.small,
      fontSize: pxToRem(18),
      fontWeight: "bold",
      borderRadius: "4px",
      px: 4,
      py: 2,
      "&[disabled]": {
        color: "#585858",
        bg: "#EBEBEB",
        boxShadow: "none",
        cursor: "not-allowed",
      },
    },
    navTop: {
      ...buttonReset,
      ...focusOutline,
      fontSize: 16,
      fontWeight: "bold",
      letterSpacing: "0.01em",
      textAlign: "start",
      margin: "0",
    },
    underline: {
      "--underline-thickness": "3px",
      content: "''",
      position: "absolute",
      bottom: "calc(var(--underline-thickness) * -1)",
      left: "0",
      right: "0",
      height: "var(--underline-thickness)",
      backgroundColor: "yellow",
      borderRadius: "2px",
    },
    heroButton: {
      ...buttonReset,
      ...focusOutlineTight,
      padding: "14px 24px",
      borderRadius: "4px",
      marginBottom: "4",
      fontWeight: 700,
      fontSize: "18px",
      "@media all and (min-width: 960px)": {
        fontSize: "20px",
      },
    },
  },
  searchFilter: {
    bg: "white",
    color: "black",
    ...boxShadows.small,
    borderRadius: "4px",
    width: "100%",
    maxWidth: "1100px",
    p: [3, null, 5, null, null, 6],
    // p: 4,
    ul: {
      columnCount: [null, null, null, null, 3],
      li: {
        display: "inline-block",
        width: "100%",
        mb: 3,
        label: {
          display: "block",
          pl: 4,
          position: "relative",
          lineHeight: 1.2,
          cursor: "pointer",
        },
        ".ais-RefinementList-checkbox": {
          position: "absolute",
          top: pxToRem(3),
          left: 0,
        },
        ".ais-RefinementList-labelText": {
          fontSize: 2,
        },
        ".ais-RefinementList-count": {
          fontSize: 0,
          color: "grey",
        },
      },
    },
  },
  accordion: {
    button: {
      cursor: "pointer",
      position: "relative",
      ...focusOutline,
      "&::after": {
        content: "''",
        position: "absolute",
        top: "50%",
        right: "0",
        display: "block",
        height: "10px",
        width: "10px",
        marginRight: "12px",
        borderBottom: "2px solid currentColor",
        borderRight: "2px solid currentColor",
        transform: "translate(-50%,-50%) rotate(45deg)",
      },
      "&[aria-expanded='true']::after": {
        transform: "translate(-50%,-50%) rotate(225deg)",
      },
    },
  },
  cards: {
    title: {
      ...heading,
      fontSize: pxToRem([18, null, null, 24]),
      mb: "1.25em",
    },
    red: {
      ...focusOutline,
      bg: "red",
      color: "#fff",
      "&:hover": {
        bg: "white",
        color: "black",
        ...boxShadows.small,
        opacity: 1,
      },
      "&:active": {
        bg: "reds.1",
        boxShadow: (theme) => `0 0 0 .125em ${theme.colors.blue}`,
      },
    },
    yellow: {
      ...focusOutline,
      bg: "yellow",
      color: "black",
      "&:hover": {
        bg: "white",
        color: "black",
        ...boxShadows.small,
        opacity: 1,
      },
    },
    green: {
      ...focusOutline,
      bg: "green",
      color: "black",
      "&:hover": {
        bg: "white",
        color: "black",
        ...boxShadows.small,
        opacity: 1,
      },
    },
    blue: {
      ...focusOutline,
      bg: "blue",
      color: "white",
      "&:hover": {
        bg: "white",
        color: "black",
        ...boxShadows.small,
        opacity: 1,
      },
    },
    white: {
      ...focusOutline,
      bg: "white",
      color: "black",
      ...boxShadows.small,
      "&:hover": {
        bg: "white",
        color: "black",
        ...boxShadows.large,
      },
    },
    whiteToGreen: {
      ...focusOutline,
      bg: "white",
      color: "black",
      ...boxShadows.small,
      "&:hover": {
        bg: "green",
        color: "black",
        ...boxShadows.large,
        opacity: 1,
      },
    },
    whiteto: {
      yellow: {
        bg: "white",
        color: "black",
        ...focusOutline,
        ...boxShadows.small,
        "&:hover": {
          bg: "yellow",
          color: "black",
          ...boxShadows.small,
          opacity: 1,
        },
      },
      red: {
        bg: "white",
        color: "black",
        ...focusOutline,
        ...boxShadows.small,
        "&:hover": {
          bg: "red",
          color: "white",
          ...boxShadows.small,
          opacity: 1,
        },
      },
      green: {
        bg: "white",
        color: "black",
        ...focusOutline,
        ...boxShadows.small,
        "&:hover": {
          bg: "green",
          color: "black",
          ...boxShadows.small,
          opacity: 1,
        },
      },
      blue: {
        bg: "white",
        color: "black",
        ...focusOutline,
        ...boxShadows.small,
        "&:hover": {
          bg: "blue",
          color: "white",
          ...boxShadows.small,
          opacity: 1,
        },
      },
      white: {
        bg: "white",
        color: "black",
        ...focusOutline,
        ...boxShadows.small,
        "&:hover": {
          bg: "white",
          color: "black",
          ...boxShadows.small,
          opacity: 1,
        },
      },
    },
  },
  alerts: {
    red: {
      ...alertBase,
      bg: "red",
      color: "white",
      a: {
        ...styles.a,
        color: "white",
      },
    },
    green: {
      ...alertBase,
      bg: "green",
      color: "black",
      a: {
        ...styles.a,
        color: "black",
      },
    },
    blue: {
      ...alertBase,
      bg: "blue",
      color: "white",
      a: {
        ...styles.a,
        color: "white",
      },
    },
    yellow: {
      ...alertBase,
      bg: "yellow",
      color: "black",
      a: {
        ...styles.a,
        color: "black",
      },
    },
  },
  search: {
    underlineLink: {
      ...focusOutline,
      background: "none",
      border: 0,
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "18px",
      letterSpacing: "0.01em",
      textDecorationLine: "underline",
      cursor: "pointer",
    },
  },
  forms: {
    label: {
      fontSize: 2,
      fontFamily: "Graphik, sans-serif",
      fontWeight: "bold",
    },
    radio: {},
    input: {
      borderColor: "gray",
      fontSize: 2,
      fontFamily: "Graphik, sans-serif",
      "&:focus": {
        borderColor: "yellow",
        boxShadow: (t) => `0 0 0 2px ${t.colors.yellow}`,
        outline: "none",
      },
    },
    textarea: {
      borderColor: "gray",
      fontSize: 2,
      fontFamily: "Graphik, sans-serif",
      width: "100%",
      "&:focus": {
        borderColor: "yellow",
        boxShadow: (t) => `0 0 0 2px ${t.colors.yellow}`,
        outline: "none",
      },
    },
  },
}
