import { useEffect } from "react"
import useStore from "src/store"

const ClientOuter = ({ location }) => {
  const setNav = useStore(state => state.setNav)
  const closeMobileNav = useStore(state => state.closeMobileNav)

  useEffect(() => {
    setNav(null)
    closeMobileNav()
  }, [location.pathname, closeMobileNav, setNav])

  return null
}

export default ClientOuter
