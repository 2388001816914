export const pxToRem = px => {
  return Array.isArray(px)
    ? px.map(val => {
        return val ? `${val / 16}rem` : null
      })
    : `${px / 16}rem`
}

export const headerThemeFromHeroColor = heroColor => {
  return ["yellow", "green"].includes(heroColor)
    ? "dark"
    : ["red", "blue"].includes(heroColor)
    ? "light"
    : "default"
}
