import { useState, useEffect } from "react"

function useLocalStorageState(
  key,
  defaultValue = "",
  { serialize = JSON.stringify, deserialize = JSON.parse } = {}
) {
  const [state, setState] = useState(() => {
    if (typeof window !== "undefined") {
      const valueInLocalStorage =
        window.localStorage.getItem(key) || defaultValue
      if (valueInLocalStorage) {
        return deserialize(valueInLocalStorage)
      }
    }
    return typeof defaultValue === "function" ? defaultValue() : defaultValue
  })

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.localStorage.setItem(key, serialize(state))
    }
  }, [key, serialize, state])

  return [state, setState]
}

export default useLocalStorageState
