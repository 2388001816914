/** @jsx jsx */
import { jsx } from "theme-ui"
import { useEffect } from "react"
import { createPortal } from "react-dom"
import { useA11yDialog } from "react-a11y-dialog"
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock"

import Button from "src/components/Button"

const Dialog = ({
  id,
  title,
  content,
  buttonText,
  setHasSeenPerVisitorSiteWideAnnouncement,
}) => {
  // `instance` is the `a11y-dialog` instance.
  // `attr` is an object with the following keys:
  // - `container`: the dialog container
  // - `overlay`: the dialog overlay (sometimes called backdrop)
  // - `dialog`: the actual dialog box
  // - `title`: the dialog mandatory title
  // - `closeButton`:  the dialog close button
  const [instance, attr] = useA11yDialog({
    // The required HTML `id` attribute of the dialog element, internally used
    // a11y-dialog to manipulate the dialog.
    id: id,
    // The optional `role` attribute of the dialog element, either `dialog`
    // (default) or `alertdialog` to make it a modal (preventing closing on
    // click outside of ESC key).
    role: "dialog",
    // The required dialog title, mandatory in the document
    // to provide context to assistive technology.
    title: title,
  })

  useEffect(() => {
    const showHandler = () => {
      disableBodyScroll(instance.$el, {
        reserveScrollBarGap: true,
      })
    }

    const hideHandler = () => {
      setHasSeenPerVisitorSiteWideAnnouncement(true)
      enableBodyScroll(instance.$el)
    }

    if (instance) {
      instance.on("show", showHandler).show().on("hide", hideHandler)
    }

    return () => {
      clearAllBodyScrollLocks()
      if (instance) {
        instance.off("show", showHandler).off("hide", hideHandler)
        instance.destroy()
      }
    }
  }, [instance])

  let dialog = null

  if (typeof window !== "undefined") {
    dialog = createPortal(
      <div
        {...attr.container}
        className="dialog-container"
        sx={{
          position: "fixed",
          inset: 0,
          zIndex: 2,
          display: "flex",
          "&[aria-hidden='true']": {
            display: "none",
          },
        }}
      >
        <div
          {...attr.overlay}
          className="dialog-overlay"
          sx={{
            position: "fixed",
            inset: 0,
            backgroundColor: "rgba(0,0,0,0.5)",
            cursor: "pointer",
          }}
        />

        <div
          {...attr.dialog}
          sx={{
            margin: "auto",
            zIndex: 2,
            position: "relative",
            padding: "40px 30px",
            width: "90%",
            maxWidth: "600px",
            backgroundColor: (theme) => theme.colors.yellow,
            lineHeight: "2",
            maxHeight: "95vh",
            overflowY: "auto",
            borderRadius: "3px",
          }}
        >
          <h1
            {...attr.title}
            className="dialog-title"
            sx={{
              variant: "text.sectionHeading",
              marginBottom: "20px",
              textDecoration: "underline",
            }}
          >
            {title}
          </h1>

          <article
            sx={{
              marginBottom: "20px",
            }}
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />

          <footer
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              {...attr.closeButton}
              className="dialog-close"
              sx={{
                variant: "button.default",
                backgroundColor: (theme) => theme.colors.white,
                textDecoration: "underline",
              }}
            >
              {buttonText}
            </Button>
          </footer>
        </div>
      </div>,
      // Note: see gatsby-ssr.js for the dialog-portal render
      window.document.getElementById("dialog-portal")
    )
  }

  return dialog
}

export default Dialog
